import {
  Button, Card, Collapse, Input, InputNumber, Modal, Radio, Select,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'query-string';
import { getInstituteNetworkAction } from './logic';
import Loader from '../../../common/components/Loader';
import {
  addNetworkAction,
  addNetworkResetAction,
  addPiToNetworkAction, getMoreNetworkAutoSuggestAction, getNetworkAutoSuggestAction, getNetworkAutoSuggestResetAction,
} from '../Network/logic';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv';
import MultiAutoSelect from '../AutoSuggest/MultiAutoSelect'
import { getautosuggestionCancelAction, getautosuggestionResetAction, getautosuggestvalueAction } from '../AddProjectName/logic';
import { getDatasetListDataAction } from '../SearchResults/logic';
import { ListUsersAction } from '../ShareProjectModal/logic';
import { getCookie } from '../../../utils';

export default function InstitutionNetworks() {
  const dateObj = new Date();
  const { Panel } = Collapse;

  const dispatch = useDispatch();
  const { id } = parse(location.search)
  const { Option } = Select;

  const usersList = useSelector((state) => state.usersList);
  const [selectedUser, setSelectedUser] = useState('');

  const instituteNetworkResponse = useSelector((state) => state.instituteNetworkResponse)
  const networkAutoSuggest = useSelector((store) => store.networkAutoSuggest)
  const addNetworkRespose = useSelector((store) => store.addNetwork);
  const addPiToNetworkRespose = useSelector((store) => store.addPiToNewtorkData)

  const [instituteNetworkData, setInstituteNetworkData] = useState([])
  const [addToNetworkModal, setAddToNetworkModal] = useState(false);
  const [createNetworkModal, setCreateNetworkModal] = useState(false);
  const [networkName, setNetworkName] = useState('');
  const [prevNetworkName, setPrevNetworkName] = useState('');
  const [networkNameAlreadyUsed, setNetworkNameAlreadyUsed] = useState(false);
  const [therapy, setTherapy] = useState([]);
  const [indications, setIndication] = useState([]);
  const [description, setDescription] = useState('');
  const [networkNameToSearch, setNetworkNameToSearch] = useState('')
  const [fromForNetworkListInMoadal, setFromForNetworkListInModal] = useState(1)
  const [networkRadioValue, setNetworkRadioValue] = useState('')
  const [addressOfNetwork, setAddressOfNewtork] = useState({
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
  });
  const [legalEntity, setLegalEntity] = useState('')
  const [paymentStructure, setPaymentStructure] = useState('')
  const [percentCharge, setPercentCharge] = useState()
  const [networkType, setNetworkType] = useState('')
  const [surveyEmail, setSurveyEmail] = useState('')
  const [centeralizedServices, setCenteralizedServices] = useState(
    {
      embeddedSiteModal: '',
      centeralFeasibility: '',
      centralBudget: '',
      siteContracts: '',
      sitePaymentSupport: '',
      centrallyCoordinates: '',
      projectManagement: '',
      provisionOfEquipment: '',
    },
  )
  const [surveyPersonDetails, setSurveyPersonDetails] = useState(
    {
      surveyPersonName: '',
      surveyPersonContact: '',
      surveyPersonEmail: '',
    },
  )
  const [provisionOfEquipmentDesc, setProvisionOfEquipmentDesc] = useState('')
  const [feasibilityProcess, setFeasibilityProcess] = useState('')
  const therapyArea = [
    'Haematology and Oncology',
    'Oncology',
    'CNS',
    'Autoimmune/Inflammation',
    'Neurology and Psychiatry',
    'Cardiovascular',
    'Infectious Disease',
    'Metabolic/Endocrinology',
    'Cardiovascular Disorders',
    'Infectious Diseases',
    'Endocrine and Metabolism Disorders',
    'Pulmonary Disorders',
    'Genitourinary Disorders',
    'Gastroenterology',
    'Rheumatology and Orthopaedics',
    'Allergy, Immunology and Transplantation',
    'Genitourinary',
    'Vaccines (Infectious Disease)',
    'Ophthalmology',
    'Ophthalmology & Other Sensory Organ DSD',
    'Dermatology',
    'Other Therapeutic Areas',
    'Haematology',
    'Unassigned',
    'Neurology',
    'Psychiatry',
    'Endocrine',
    'Metabolism Disorders',
    'NA',
    'Orthopaedics',
    'Rheumatology',
    'Allergy',
    'Immunology',
    'Transplantation',
    'other Sensory Organ Disorders',
  ];
  const paymentStructureFilter = ['Separate budget / contract with headquarter', 'Headquarter costs covered by site payments', 'Central contract as well as covering via site payments possible']
  const NetworkTypeFilter = ['Commercial – primarily wholly owned sites', 'Commercial – primarily affiliated sites', 'Just in Time', 'Academic/informal collaboration', 'Community Access Network']
  const FeasibilityProcessFilter = ['Central feasibility contact at headquarter', 'Central feasibility contact at center', 'Directly to PI']

  useEffect(() => {
    if (Object.keys(addNetworkRespose.data).length !== 0) {
      if (addNetworkRespose.data.statusCode === 409) {
        setPrevNetworkName(networkName);
        // setNetworkName('')
        setNetworkNameAlreadyUsed(true);
      } else if (addNetworkRespose?.flag) {
        const pid = parse(location.search).id;
        const innoid = addNetworkRespose.data.innoplexus_id;
        dispatch(
          addPiToNetworkAction({
            pi_id: pid,
            body: {
              innoplexus_id: innoid,
            },
            type: 'centers',
          }),
        );
        setNetworkName('')
        setDescription('')
        setTherapy([])
        setIndication(null)
        setPrevNetworkName('')
        setCreateNetworkModal(false);
      }
    }
  }, [addNetworkRespose]);

  useEffect(() => {
    if (addPiToNetworkRespose.flag) {
      dispatch(addNetworkResetAction({}))
      setAddToNetworkModal(false)
      setNetworkRadioValue('')
      dispatch(getDatasetListDataAction(
        {
          dataset: 'networks',
          from: 1,
          size: 10,
          pi_id: parse(location.search).id,
        },
      ))
    }
  }, [JSON.stringify(addPiToNetworkRespose)])

  useEffect(() => {
    if (addPiToNetworkRespose.error) {
      dispatch(getNetworkAutoSuggestResetAction())
      setNetworkNameToSearch('')
      setNetworkRadioValue('')
      setAddToNetworkModal(false)
    }
  }, [JSON.stringify(addPiToNetworkRespose)])

  useEffect(() => {
    if (networkName !== '' && networkName === prevNetworkName) {
      setNetworkNameAlreadyUsed(true);
    } else {
      setNetworkNameAlreadyUsed(false);
    }
  }, [networkName])

  useEffect(() => {
    if (instituteNetworkResponse.flag) {
      setInstituteNetworkData(instituteNetworkResponse.data.data)
    }
  }, [JSON.stringify(instituteNetworkResponse)])

  useEffect(() => {
    dispatch(getInstituteNetworkAction({ id }))
    dispatch(ListUsersAction())
  }, [])

  const handleAddAddress = (value, type) => {
    // console.log("value==>",type)
    switch (type) {
      case 'line1':
        setAddressOfNewtork({ ...addressOfNetwork, line1: value })
        break
      case 'line2':
        setAddressOfNewtork({ ...addressOfNetwork, line2: value })
        break
      case 'city':
        setAddressOfNewtork({ ...addressOfNetwork, city: value })
        break
      case 'state':
        setAddressOfNewtork({ ...addressOfNetwork, state: value })
        break
      case 'zip':
        setAddressOfNewtork({ ...addressOfNetwork, zip: value })
        break
      default: return null
    }
    return undefined
  }

  const renderHeader = (itm) => {
    return (
      <div className="network-tab-content">
        <div className="network-tab-content-data">
          <div className="network-tab-content-data-in">
            <div className="network-tab-content-data-in-title">
              Network Name:
            </div>
            <div className="network-tab-content-data-in-value">{itm.name}</div>
          </div>
          <div className="network-tab-content-data-in">
            <div className="network-tab-content-data-in-title">
              Indication:
            </div>
            <div className="network-tab-content-data-in-value">{itm.indications.length ? itm.indications.join(', ') : '-' }</div>
          </div>
          <div className="network-tab-content-data-in">
            <div className="network-tab-content-data-in-title">
              Therapy Area
            </div>
            <div className="network-tab-content-data-in-value">{(itm.therapy_area && itm.therapy_area.length > 0) ? itm.therapy_area.join(', ') : '-' }</div>
          </div>
        </div>
        <div className="network-tab-content-data">
          <div className="network-tab-content-data-in">
            <div className="network-tab-content-data-in-title">
              Network Type:
            </div>
            <div className="network-tab-content-data-in-value">{itm.type ? itm.type : '-'}</div>
          </div>
          <div className="network-tab-content-data-in">
            <div className="network-tab-content-data-in-title">
              Survey Email Address:
            </div>
            <div className="network-tab-content-data-in-value">{itm.survey_email ? itm.survey_email : '-' }</div>
          </div>
        </div>
        <div className="network-tab-content-data">
          <div className="network-tab-content-data-in">
            <div>
              Description:
            </div>
            <div>{itm.description ? itm.description : '-' }</div>
          </div>
        </div>
      </div>
    )
  }

  const renderInnerData = () => {
    return (
      <div className="network-tab-content-inner">
        <div className="network-tab-content-inner-data">
          <div className="network-tab-content-inner-data-main-title">
            Centralised services offered
          </div>
          <div className="network-tab-content-inner-data-in">
            <div className="network-tab-content-inner-data-in-title">
              Embedded site model
            </div>
            <div className="network-tab-content-inner-data-in-value"> Yes </div>
          </div>
          <div className="network-tab-content-inner-data-in">
            <div className="network-tab-content-inner-data-in-title">
              Central feasibility
            </div>
            <div className="network-tab-content-inner-data-in-value"> No </div>
          </div>
          <div className="network-tab-content-inner-data-in">
            <div className="network-tab-content-inner-data-in-title">
              Central budget, central contract
            </div>
            <div className="network-tab-content-inner-data-in-value"> Yes, Optional </div>
          </div>
        </div>
        <div className="network-tab-content-inner-data">
          <div className="network-tab-content-inner-data-main-title">
            Feasibility contact person
          </div>
        </div>
      </div>
    )
  }
  const NetworkRadioChanged = (e) => {
    setNetworkRadioValue(e.target.value)
  }

  const renderAutoSuggestOptions = () => {
    if (networkAutoSuggest.data.data.length === 0) {
      return <div className="no-data-found-msg">No Network found</div>
    }
    return (
      <Radio.Group onChange={NetworkRadioChanged} value={networkRadioValue}>{networkAutoSuggest.data.data.map((itm, index) => {
        return (
          <Card id={index} className="Card add-network-card">
            <div className="title-card">{itm.name}</div>
            <div className="indiaction-content"><span className="network-search-title">Indication:</span>{itm.indications.length ? itm.indications.join(',') : '-'}</div>
            <div className="therapy-area-content"><span className="network-search-title">Therapy area:</span>{itm.therapy_area}</div>
            <div className="principale-investigator-content"><span className="network-search-title">Principle investigator count</span>{itm.principal_investigators_count}</div>
            <div className="radio-btn-section">
              <Radio value={itm.iid} />
            </div>
          </Card>
        )
      })}
      </Radio.Group>
    )
  }

  const onNetworkNameInputChange = (e) => {
    setNetworkNameToSearch(e)
    if (e.trim() && e.length > 2) {
      setNetworkNameToSearch(e)
      dispatch(
        getNetworkAutoSuggestAction({
          query: networkNameToSearch.trim(),
          from: 1,
          dataset: 'networks',
          size: 10,
        }),
        // setFromForNetworkListInModal(fromForNetworkListInMoadal + 1),
      )
    }
  }

  const addPiToNetwork = () => {
    const pid = parse(location.search).id;
    const innoid = networkRadioValue;
    dispatch(
      addPiToNetworkAction({
        pi_id: pid,
        body: {
          innoplexus_id: innoid,
        },
        type: 'centers',
      }),
    );
  }
  const onNetworkNameInputChangeLoadMore = () => {
    dispatch(
      getMoreNetworkAutoSuggestAction({
        query: networkNameToSearch,
        from: fromForNetworkListInMoadal,
        dataset: 'networks',
        size: 10,
      }),
      setFromForNetworkListInModal(fromForNetworkListInMoadal + 1),
    )
  }

  const submitNewNetwork = () => {
    dispatch(
      addNetworkAction({
        body: {
          name: networkName,
          indications,
          therapy_area: therapy,
          description,
          address: {
            address_line_1: addressOfNetwork.line1,
            address_line_2: addressOfNetwork.line2,
            city: addressOfNetwork.city,
            state: addressOfNetwork.state,
            zip: addressOfNetwork.zip,
          },
          legal_entity: legalEntity,
          payment_structure: paymentStructure,
          percent_overhead_charged: percentCharge,
          network_affiliation: networkType,
          survey_email: surveyEmail,
          network_type: {
            embedded_site_model: centeralizedServices.embeddedSiteModal,
            central_feasibility: centeralizedServices.centeralFeasibility,
            central_budget: centeralizedServices.centralBudget,
            // central_contract: centeralizedServices.
            site_contracts: centeralizedServices.siteContracts,
            // central_assistance_only
            site_payment_support: centeralizedServices.sitePaymentSupport,
            // centrally_coordinated_patient_recruitment_services : centeralizedServices.
            project_management: centeralizedServices.projectManagement,
            provision_of_equipment: centeralizedServices.provisionOfEquipment,
            provision_of_equipment_description: provisionOfEquipmentDesc,
          },
          feasibility_contact_details: {
            name: surveyPersonDetails.surveyPersonName,
            email: surveyPersonDetails.surveyPersonEmail,
            phone_number: surveyPersonDetails.surveyPersonContact,
          },
          feasibility_process: feasibilityProcess,
        },
      }),
    );
  };

  const handleTherapyChange = (Therapy) => {
    setTherapy(Therapy);
  };

  const handleAddToNetwork = () => {
    setAddToNetworkModal(true)
    dispatch(getNetworkAutoSuggestResetAction())
    setNetworkNameToSearch('')
  }

  const onIndicationChangeInput = (e) => {
    if (e.trim() && e.length > 2) {
      dispatch(
        getautosuggestvalueAction({
          query: e.trim(),
          type: 'indications',
        }),
      );
    } else {
      dispatch(
        getautosuggestvalueAction({
          query: '',
          type: 'indications',
        }),
      );
    }
  };

  const handleOnChange = (k) => {
    const name = usersList?.data?.data?.users?.filter((itm) => itm.id === k)
    setSelectedUser(name[0].name);
  }

  useEffect(() => {
    if (selectedUser === '') {
      setNetworkName(`_${therapy?.join('_')}_${indications?.join('_')}_${getCookie('name')}__${dateObj.getUTCFullYear()}${dateObj.getUTCMonth() + 1 < 10 ? '0'.concat(dateObj.getUTCMonth() + 1) : dateObj.getUTCMonth() + 1}${dateObj.getUTCDate() < 10 ? '0'.concat(dateObj.getUTCDate()) : dateObj.getUTCDate()}`);
    } else {
      setNetworkName(`_${therapy?.join('_')}_${indications?.join('_')}_${selectedUser}__${dateObj.getUTCFullYear()}${dateObj.getUTCMonth() + 1 < 10 ? '0'.concat(dateObj.getUTCMonth() + 1) : dateObj.getUTCMonth() + 1}${dateObj.getUTCDate() < 10 ? '0'.concat(dateObj.getUTCDate()) : dateObj.getUTCDate()}`);
    }
  }, [therapy, indications, selectedUser])

  return (
    <div className="network-innder-data-tab">
      <Loader loading={instituteNetworkResponse.loading} error={instituteNetworkResponse.error}>
        {instituteNetworkData.length > 0 ? instituteNetworkData.map((itm, idx) => {
          return (
            <Collapse defaultActiveKey={[0]} ghost expandIconPosition="end">
              <Panel header={renderHeader(itm)} key={idx}>
                <>{renderInnerData()}</>
              </Panel>
            </Collapse>
          )
        })
          : <div className="no-data-inside">Not enrolled in any network</div>}
      </Loader>

      <Modal
        title="Add to a network"
        className="add-to-a-network-modal"
        visible={addToNetworkModal}
        onCancel={() => {
          dispatch(getNetworkAutoSuggestResetAction())
          setNetworkNameToSearch('')
          setAddToNetworkModal(false)
          setNetworkRadioValue('')
        }}
        okText="Add"
        onOk={() => addPiToNetwork()}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ disabled: networkRadioValue === '' }}
        maskClosable={false}
      >
        <div className="add-to-a-network-content">
          <Input
            placeholder="Search Network name here"
            value={networkNameToSearch}
            onChange={(e) => onNetworkNameInputChange(e.target.value)}

          />
          <div className="Card">

            <Loader loading={networkAutoSuggest.loading} error={networkAutoSuggest.error}>
              {!networkAutoSuggest.loading && networkAutoSuggest.flag && (
              <LazyLoadDiv
                className="card-list scrollbar"
                id="pi-list-in-search"
                total={networkAutoSuggest.data.total}
                currentTotal={(networkAutoSuggest.data.data || []).length}
                loadMoreRows={() => onNetworkNameInputChangeLoadMore()}
                height="calc(100vh - 465px)"
              >
                {renderAutoSuggestOptions()}
              </LazyLoadDiv>
              )}
            </Loader>
          </div>
          <div className="create-new-network-content">
            <Button
              type="primary"
              className="create-new-network"
              onClick={() => {
                setAddToNetworkModal(false);
                setCreateNetworkModal(!createNetworkModal);
              }}
            >
              Create new network
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        title="Create a network"
        visible={createNetworkModal}
        className="create-network-modal-main"
        onCancel={() => setCreateNetworkModal(false)}
        okText="Save"
        okButtonProps={{ disabled: networkName.length < 1 || networkNameAlreadyUsed }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={submitNewNetwork}
        maskClosable={false}
      >
        <div className="create-network-content">
          <div className="create-network-input">
            <label>Therapy Area</label>
            <Select
              mode="multiple"
              allowClear
              style={{
                width: '100%',
              }}
              placeholder="Select Therapy area"
              onChange={handleTherapyChange}
            >
              {therapyArea.map((area) => (
                <Option key={area}>{area}</Option>
              ))}
            </Select>
          </div>

          <div className="create-network-input">
            <label>Indication</label>
            <MultiAutoSelect
              apiCall={onIndicationChangeInput}
              apiName="autosuggestdata"
              selections={indications}
              setValue={(val) => {
                setIndication(val);
              }}
              cancelAction={getautosuggestionCancelAction}
              resetAction={getautosuggestionResetAction}
              key="indication"
            />
          </div>

          <div className="create-network-input">
            <label>Owner name</label>
            <Select
              style={{
                width: '100%',
              }}
              disabled={usersList.loading}
              value={selectedUser === '' ? getCookie('name') : selectedUser}
              // bordered={false}
              // className="share-search-input"
              placeholder="Search name here"
              onChange={handleOnChange}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
              filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            >
              {usersList?.data?.data?.users?.map((itm) => (
                <Option
                  value={itm.id}
                  disabled={getCookie('userName') === itm.id}
                >
                  {itm.name}
                </Option>
              ))}
            </Select>
          </div>

          <div className="create-network-input">
            <label>Network Name
              <span className="asterisk-red">*</span>
            </label>
            <Input
              placeholder="Enter Network name"
              value={networkName}
              onChange={(e) => setNetworkName(e.target.value)}
            />
            {networkNameAlreadyUsed ? (
              <div className="error-msg">
                This Network name already Used Please try With different Network name !{' '}
              </div>
            ) : null}
          </div>

          <div className="create-network-input" style={{ minWidth: '96%' }}>
            <label>Description</label>
            <Input
              placeholder="Enter Description for Network"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="create-network-input create-network-input-section create-network-input-section-top" style={{ minWidth: '47%', marginRight: 0, paddingRight: '20px' }}>
            <label>Address line 1</label>
            <Input
              placeholder="Enter address line 1"
              value={addressOfNetwork.line1}
              onChange={(e) => handleAddAddress(e.target.value, 'line1')}
            />
          </div>
          <div className="create-network-input create-network-input-section create-network-input-section-top" style={{ minWidth: '49%', marginLeft: 0, paddingLeft: '20px' }}>
            <label>Address Line 2</label>
            <Input
              placeholder="Enter address line 2"
              value={addressOfNetwork.line2}
              onChange={(e) => handleAddAddress(e.target.value, 'line2')}
            />
          </div>
          <div className="create-network-input">
            <label>City</label>
            <Input
              placeholder="Enter city"
              value={addressOfNetwork.city}
              onChange={(e) => handleAddAddress(e.target.value, 'city')}
            />
          </div>
          <div className="create-network-input">
            <label>State</label>
            <Input
              placeholder="Enter state"
              value={addressOfNetwork.state}
              onChange={(e) => handleAddAddress(e.target.value, 'state')}
            />
          </div>
          <div className="create-network-input">
            <label>Zip code</label>
            <Input
              placeholder="Enter zip code"
              value={addressOfNetwork.zip}
              onChange={(e) => handleAddAddress(e.target.value, 'zip')}
            />
          </div>
          <div className="create-network-input">
            <label>Legal entity</label>
            <Input
              placeholder="Enter legal entity"
              value={legalEntity}
              onChange={(e) => setLegalEntity(e.target.value)}
            />
          </div>
          <div className="create-network-input">
            <label>Payment structure</label>
            <Select
              style={{
                width: '100%',
              }}
              placeholder="Select payment structure"
              onChange={(value) => setPaymentStructure(value)}
              value={paymentStructure}
            >
              {paymentStructureFilter.map((str) => (
                <Option key={str}>{str}</Option>
              ))}
            </Select>
          </div>
          <div className="create-network-input">
            <label>Percent overhead charged</label>
            <InputNumber
              placeholder="Enter percent overhead charged"
              value={percentCharge}
              onChange={(e) => setPercentCharge(e)}
            />
          </div>
          <div className="create-network-input">
            <label>Network Type</label>
            <Select
              style={{
                width: '100%',
              }}
              placeholder="Select Network Type"
              onChange={(value) => setNetworkType(value)}
              value={networkType}
            >
              {NetworkTypeFilter.map((str) => (
                <Option key={str}>{str}</Option>
              ))}
            </Select>
            <div className="create-network-input">
              <label> Survey Email Address</label>
              <Input
                placeholder="Enter survey address"
                value={surveyEmail}
                onChange={(e) => setSurveyEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="create-network-input create-network-input-section">
            <label className="main-title">Centralized services offered</label>
            <div className="create-network-radio-group">
              <div className="create-network-radio-input">
                <label>Embedded site model</label>
                <Radio.Group onChange={(e) => setCenteralizedServices({ ...centeralizedServices, embeddedSiteModal: e.target.value })} value={centeralizedServices.embeddedSiteModal}>
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </div>
              <div className="create-network-radio-input">
                <label>Central feasibility</label>
                <Radio.Group onChange={(e) => setCenteralizedServices({ ...centeralizedServices, centeralFeasibility: e.target.value })} value={centeralizedServices.centeralFeasibility}>
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </div>
              <div className="create-network-radio-input">
                <label>Central budget, central contract</label>
                <Radio.Group onChange={(e) => setCenteralizedServices({ ...centeralizedServices, centralBudget: e.target.value })} value={centeralizedServices.centralBudget}>
                  <Radio value="Yes MANDATORY">Yes, Mandatory (Potential budget to be confirmed)</Radio>
                  <Radio value="Yes OPTIONAL"> Yes, Optional (Potential budget to be confirmed)</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </div>
              <div className="create-network-radio-input">
                <label>Site contracts, central assistance only</label>
                <Radio.Group onChange={(e) => setCenteralizedServices({ ...centeralizedServices, siteContracts: e.target.value })} value={centeralizedServices.siteContracts}>
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </div>
              <div className="create-network-radio-input">
                <label>Site payment support</label>
                <Radio.Group onChange={(e) => setCenteralizedServices({ ...centeralizedServices, sitePaymentSupport: e.target.value })} value={centeralizedServices.sitePaymentSupport}>
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </div>
              <div className="create-network-radio-input">
                <label>Centrally coordinated patient recruitment services</label>
                <Radio.Group onChange={(e) => setCenteralizedServices({ ...centeralizedServices, centrallyCoordinates: e.target.value })} value={centeralizedServices.centrallyCoordinates}>
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </div>
              <div className="create-network-radio-input">
                <label>Project management</label>
                <Radio.Group onChange={(e) => setCenteralizedServices({ ...centeralizedServices, projectManagement: e.target.value })} value={centeralizedServices.projectManagement}>
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </div>
              <div className="create-network-radio-input extra-field-input">
                <label>Provision of equipment</label>
                <Radio.Group onChange={(e) => setCenteralizedServices({ ...centeralizedServices, provisionOfEquipment: e.target.value })} value={centeralizedServices.provisionOfEquipment}>
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
                {centeralizedServices.provisionOfEquipment === 'Yes'
                  ? (
                    <div className="extra-field-input-sec">
                      <label>Provision of equipment description</label>
                      <Input
                        placeholder="Enter provision of equipment description"
                        value={provisionOfEquipmentDesc}
                        onChange={(e) => setProvisionOfEquipmentDesc(e.target.value)}
                      />
                    </div>
                  ) : null}
              </div>
            </div>
          </div>
          <div className="create-network-input create-network-input-section">
            <label>Feasibility contact person</label>
            <div className="create-network-combine-input">
              <div className="create-network-input">
                <label>Name</label>
                <Input
                  placeholder="Enter name of feasibility contact person"
                  value={surveyPersonDetails.surveyPersonName}
                  onChange={(e) => setSurveyPersonDetails({ ...surveyPersonDetails, surveyPersonName: e.target.value })}
                />
              </div>
              <div className="create-network-input">
                <label>Contact Number</label>
                <Input
                  placeholder="Enter contact number of feasibility contact person"
                  value={surveyPersonDetails.surveyPersonContact}
                  onChange={(e) => setSurveyPersonDetails({ ...surveyPersonDetails, surveyPersonContact: e.target.value })}
                />
              </div>
              <div className="create-network-input">
                <label>Email</label>
                <Input
                  placeholder="Enter email of feasibility contact person"
                  value={surveyPersonDetails.surveyPersonEmail}
                  onChange={(e) => setSurveyPersonDetails({ ...surveyPersonDetails, surveyPersonEmail: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="create-network-input" style={{ minWidth: '97%' }}>
            <label>Feasibility process</label>
            <Select
              style={{
                minWidth: '100%',
              }}
              placeholder="Select feasibility process"
              onChange={(value) => setFeasibilityProcess(value)}
              value={feasibilityProcess}
            >
              {FeasibilityProcessFilter.map((str) => (
                <Option key={str}>{str}</Option>
              ))}
            </Select>
          </div>
        </div>
      </Modal>
      <div className="plus-add-btn">
        <button
          onClick={() => handleAddToNetwork()}
          type="button"
          className="plus-btn"
        >
          <span className="title" style={{ display: 'none' }}>
            Add to Network&nbsp;
          </span>
          <span className="title-plus"> +</span>
        </button>
      </div>

    </div>
  )
}
