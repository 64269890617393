import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { parse } from 'query-string';
import { Button, Tag } from 'antd';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv/index';
import Loader from '../../../common/components/Loader';
import { getDatasetListDataAction, getMoreDatasetListDataAction } from '../SearchResults/logic';
import Template from '../Template';
import { createBookmarkResetAction, deleteBookmarkResetAction } from '../../../common/components/Bookmark/logic';
import './template.scss'
import { addPiAction, addPiResetAction } from './logic';
import MergeViewModal from '../MergeViewModal';
import { getCookie } from '../../../utils';

const DocumentList = ({ ...props }) => {
  const dispatch = useDispatch();
  const [from, setFrom] = useState(1);
  const [currentTab, setCurrentTab] = useState('');
  const [listData, setListData] = useState([]);
  const [datasetsTotal, setDatasetsTotal] = useState(0);
  const [checkboxVal, setCheckboxVal] = useState(-1);
  const datasetListData = useSelector((state) => state.datasetListData);
  const createBookmark = useSelector((store) => store.createBookmark);
  const deleteBookmark = useSelector((store) => store.deleteBookmark);
  const addPiResponse = useSelector((store) => store.addPiResponse);
  const projectsData = useSelector((store) => store.projectsData);
  const [tierClicked, setTierClicked] = useState(false)
  const [isMergeModalOpenInList, setIsMergeModalOpenInList] = useState(false)
  const [addPi, setAddPi] = useState({});
  const advanceFilterData = localStorage.getItem('advance_search')
  const adminType = getCookie('permissions')

  useEffect(() => {
    if (createBookmark.flag) {
      dispatch(createBookmarkResetAction())
      if (props.centerId) {
        setFrom(1);
        const p = {};
        p[props.selectSortBy] = props.sortFilter;
        dispatch(
          getDatasetListDataAction({
            center_type: props.centerType,
            center_id: props.centerId,
            dataset: props.activeDataset === 'parent_centers' ? 'centers' : props.activeDataset,
            from,
            size: 10,
            filters: props.appliedFilters ? JSON.stringify(props.appliedFilters) : advanceFilterData || undefined,
            order_by: props.selectSortBy !== '' ? JSON.stringify(p) : undefined,
            partial_match: props.partialMatch ? props.partialMatch : undefined,
          }),
        );
      }
    }
    if (deleteBookmark.flag) {
      dispatch(deleteBookmarkResetAction())
      if (props.centerId) {
        setFrom(1);
        const p = {};
        p[props.selectSortBy] = props.sortFilter;

        dispatch(
          getDatasetListDataAction({
            center_type: props.centerType,
            center_id: props.centerId,
            dataset: props.activeDataset === 'parent_centers' ? 'centers' : props.activeDataset,
            from,
            size: 10,
            filters: props.appliedFilters ? JSON.stringify(props.appliedFilters) : advanceFilterData || undefined,
            order_by: props.selectSortBy !== '' ? JSON.stringify(p) : undefined,
            partial_match: props.partialMatch ? props.partialMatch : undefined,

          }),
        );
      }
    }
  }, [JSON.stringify(createBookmark), JSON.stringify(deleteBookmark)])

  useEffect(() => {
    if (props.activeDataset !== currentTab) {
      setCurrentTab(props.activeDataset);
      setFrom(1);
    }
  }, [JSON.stringify(props)]);

  useEffect(() => {
    if (datasetListData.flag) {
      setListData(datasetListData.data.data);
      setDatasetsTotal(datasetListData.data.total);
    }
  }, [JSON.stringify(datasetListData)]);

  useEffect(() => {
    if (addPiResponse.flag && !addPiResponse.error) {
      props.modalStatus(false)
      dispatch(addPiResetAction())
    }
    if (addPiResponse.error) {
      setCheckboxVal(-1)
      setAddPi({})
      dispatch(addPiResetAction())
    }
  }, [JSON.stringify(addPiResponse)]);

  // useEffect(() => {
  //   return (() => {
  //     setIsMergeModalOpen(false)
  //   })
  // }, [])

  useEffect(() => {
    if (props.centerId) {
      setFrom(1);
      dispatch(
        getDatasetListDataAction({
          center_type: props.centerType,
          center_id: props.centerId,
          dataset: props.activeDataset === 'parent_centers' ? 'centers' : props.activeDataset,
          from: 1,
          size: 10,
          filters: props.appliedFilters ? JSON.stringify(props.appliedFilters) : advanceFilterData || undefined,
          partial_match: props.partialMatch ? props.partialMatch : undefined,
        }),
      );
    }
  }, [JSON.stringify(props.centerType)]);

  // useEffect(() => {
  //   if (!isMergeModalOpenInList) {
  //     setCenterForMerging({
  //       isCenterSelected: false,
  //       centerOneId: '',
  //       centerTwoId: '',
  //       centerOneData: {},
  //       centerTwoData: {},
  //     })
  //     setPiForMerging({
  //       isPiSelected: false,
  //       piOneId: '',
  //       piTwoId: '',
  //       piOneData: {},
  //       piTwoData: {},
  //     })
  //   }
  // }, [isMergeModalOpenInList])

  const loadMoreRows = () => {
    const p = {};
    p[props.selectSortBy] = props.sortFilter;
    if (advanceFilterData) {
      const finalFilter = JSON.parse(advanceFilterData)
      dispatch(
        getMoreDatasetListDataAction({
          center_type: props.centerType,
          center_id: props.centerId,
          query: props.query,
          dataset: props.activeDataset === 'parent_centers' ? 'centers' : props.activeDataset,
          from: from + 1,
          size: 10,
          filters: props.activeDataset === 'parent_centers' ? JSON.stringify({ ...props.appliedFilters, ...finalFilter, parent_flag: true }) : JSON.stringify({ ...props.appliedFilters, ...finalFilter, parent_flag: false }),
          order_by: props.selectSortBy !== '' ? JSON.stringify(p) : undefined,
          partial_match: props.partialMatch ? props.partialMatch : undefined,
        }),
      );
    } else {
      dispatch(
        getMoreDatasetListDataAction({
          center_type: props.centerType,
          center_id: props.centerId,
          query: props.query,
          dataset: props.activeDataset === 'parent_centers' ? 'centers' : props.activeDataset,
          from: from + 1,
          size: 10,
          filters: props.appliedFilters && props.activeDataset === 'parent_centers' ? JSON.stringify({ ...props.appliedFilters, parent_flag: true }) : JSON.stringify({ ...props.appliedFilters, parent_flag: false }),
          order_by: props.selectSortBy !== '' ? JSON.stringify(p) : undefined,
          partial_match: props.partialMatch ? props.partialMatch : undefined,
          search_fields: props.searchFields && props.searchFields.length !== 0 ? props.searchFields.join(',') : undefined,
        }),
      );
    }

    setFrom(from + 1);
  };

  const handleNextButton = () => {
    dispatch(addPiAction(addPi))
    // dispatch(addPiResetAction())
  }

  const checkDisabled = () => {
    if (Object.keys(addPi).length > 0) {
      return false
    }
    return true
  }

  const handleMergeClicked = (type) => {
    setIsMergeModalOpenInList(true)
    props.setMergeType(type)
    return null
  }
  const handleCheckboxVal = (index, checked) => {
    if (index === checkboxVal) {
      setCheckboxVal(-1)
    }
    if (checked) {
      setCheckboxVal(index)
      const finalData = {
        project_id: props.analysisId,
        site_id: listData[index].affiliated_site_id,
        pi_id: listData[index].id,
        is_cfl: adminType.includes('cfl_access') ? true : undefined,
        is_lcor: adminType.includes('lcor_access') ? true : undefined,
      }
      setAddPi(finalData)
    }
  }

  const handleCancelButton = () => {
    props.modalStatus(false)
  }

  const [selectedPiId, setSelectedPiId] = useState('');

  const handleTagClose = (id, type) => {
    if (type === 'center') {
      const newList = props.centerForMerging?.centerTwoId.filter((item) => item !== id)
      const newListData = props.centerForMerging?.centerTwoData.filter((item) => item.id !== id)
      props.setCenterForMerging({ ...props.centerForMerging, centerTwoId: newList, centerTwoData: newListData })
    }
    if (type === 'pi') {
      const newList = props.piForMerging?.piTwoId.filter((item) => item !== id)
      const newListData = props.piForMerging?.piTwoData.filter((item) => item.id !== id)
      props.setPiForMerging({ ...props.piForMerging, piTwoId: newList, piTwoData: newListData })
    }
    return null
  }
  const getTags = (type) => {
    if (type === 'center') {
      return (
        <div className="tag-sigle">
          {
            props.centerForMerging.centerTwoData.map((ele, index) => (
              <Tag closable onClose={() => handleTagClose(ele.id, type)}>
                <div className="tag-title">Duplicate {index + 1}</div>
                {ele.name}
              </Tag>
            ))
          }
        </div>
      )
    }
    if (type === 'pi') {
      return (
        <div className="tag-sigle">
          {
            props.piForMerging.piTwoData.map((ele, index) => (
              <Tag closable onClose={() => handleTagClose(ele.id, type)}>
                <div className="tag-title">Duplicate {index + 1}</div>
                {ele.name}
              </Tag>
            ))
          }
        </div>
      )
    }
    return null
  }

  const handleMasterTagClose = (data, type) => {
    if (type === 'center') {
      props.setCenterForMerging({
        isCenterSelected: false,
        centerOneId: '',
        centerTwoId: [],
        centerOneData: {},
        centerTwoData: [],
      })
    }
    if (type === 'pi') {
      props.setPiForMerging({
        isPiSelected: false,
        piOneId: '',
        piTwoId: [],
        piOneData: {},
        piTwoData: [],
      })
    }
  }
  return (
    <>
      <div className="CardList principle-cardlist">
        {/* <div className="merged-tags-main">
          <div className="merged-tags-header"> Merge Duplicate Centers</div>
          <div className="merged-tags-list">{getTags('center')}</div>
        </div>
        )} */}
        {props?.centerForMerging?.isCenterSelected && props?.centerForMerging?.centerOneData !== undefined && (
          <div className="merged-tags-main">
            <div className="merged-tags-header"> Merge Duplicate {parse(location.search).currentTab === 'parent_centers' ? 'Parent Institutions' : 'Centers'}</div>
            <div className="combine-tag-btn">
              <div className="tag-sigle-gr">
                <div className="tag-sigle">
                  <Tag closable onClose={() => handleMasterTagClose(props.centerForMerging.centerOneData.id, 'center')}>
                    <div className="tag-title">Master Center</div>
                    {props.centerForMerging.centerOneData.name}
                  </Tag>

                </div>
                {props?.centerForMerging?.isCenterSelected && props?.centerForMerging?.centerTwoData !== undefined && props?.centerForMerging?.centerTwoData.length > 0 && (
                  <div className="merged-tags-list">{getTags('center')}</div>
                )}
              </div>
              <div className="merge-btn">
                {props?.centerForMerging?.isCenterSelected && props?.centerForMerging?.centerTwoId !== undefined && props?.centerForMerging?.centerTwoId.length > 0 && <Button type="primary" onClick={() => handleMergeClicked(props.activeDataset === 'parent_centers' ? 'parent' : 'center')} className="source">{props.activeDataset === 'parent_centers' ? 'Merge Parent Institutions' : 'Merge Centers'}</Button>}
              </div>
            </div>

          </div>
        )}
        {props?.piForMerging?.isPiSelected && props?.piForMerging?.piOneData !== undefined && (
          <div className="merged-tags-main">
            <div className="merged-tags-header"> Merge Duplicate Investigators</div>
            <div className="combine-tag-btn">
              <div className="tag-sigle-gr">
                <div className="tag-sigle">
                  <Tag closable onClose={() => handleMasterTagClose(props.piForMerging.piOneData.id, 'pi')}>
                    <div className="tag-title">Master Investigator</div>
                    {props.piForMerging.piOneData.name}
                  </Tag>
                </div>
                {props.piForMerging.isPiSelected && props.piForMerging.piTwoData !== undefined && props.piForMerging.piTwoData.length > 0 && (
                  <div className="merged-tags-list">{getTags('pi')}</div>
                )}
              </div>
              <div className="merge-btn" />
              {props?.piForMerging?.isPiSelected && props?.piForMerging?.piTwoId !== undefined && props?.piForMerging?.piTwoId.length > 0 && <Button type="primary" onClick={() => handleMergeClicked('pi')} className="source">Merge Investigators</Button>}
            </div>

          </div>
        )}
        <Loader loading={props.centerId && datasetListData.loading} error={datasetListData.error}>
          {listData && listData.length
            ? (
              <LazyLoadDiv
                className="card-list scrollbar"
                id="dataset-list"
                total={datasetsTotal}
                currentTotal={(listData || []).length}
                loadMoreRows={() => loadMoreRows()}
                height="calc(100vh - 346px)"
              >
                {listData.map((item, index) => <Template type={item.type} itm={item} index={index} IsModalProps={props.IsModalProps} analysisId={props.analysisId} setAddPi={setAddPi} checkboxVal={checkboxVal} handleCheckboxVal={handleCheckboxVal} setTierClicked={setTierClicked} tierClicked={tierClicked} selectedPiId={selectedPiId} setSelectedPiId={setSelectedPiId} projectsData={projectsData} fromSearchResult centerForMerging={props.centerForMerging} setCenterForMerging={props.setCenterForMerging} piForMerging={props.piForMerging} setPiForMerging={props.setPiForMerging} handleMergeClicked={handleMergeClicked} setSelectedFromSearchPI={props.setSelectedFromSearchPI} selectedFromSearchPI={props.selectedFromSearchPI} fromUploadSponsor={props.fromUploadSponsor} />)}
              </LazyLoadDiv>
            )
            : (
              <div className="no-data-found-msg">
                No relevant data found
              </div>
            )}
          {datasetListData.flag && listData.length && !props.IsModalProps
            ? (
              <div className="show-doc-outof">
                <div className="show-doc-data">
                  Showing<span className="show-count">{listData.length}</span> out of
                  <span className="show-count-number">{datasetsTotal}</span>results
                </div>
              </div>
            )
            : !props.fromUploadSponsor && (
              <Loader loading={addPiResponse.loading}>

                <div className="show-doc-outof">
                  <div className="show-doc-data">
                    Showing<span className="show-count">{listData.length}</span> out of
                    <span className="show-count-number">{datasetsTotal}</span>results
                  </div>
                  {props.IsModalProps && !props.fromUploadSponsor && (
                    <div className="btn-wrap">
                      <Button className="cancel-btn" type="primary" onClick={handleCancelButton}>Cancel</Button>
                      <Button className="next-btn" type="primary" onClick={handleNextButton} disabled={checkDisabled()}>Add</Button>
                    </div>
                  )}
                </div>
              </Loader>
            )}
        </Loader>
      </div>
      {isMergeModalOpenInList && props.mergeType === 'center' ? <MergeViewModal type={props.mergeType} data={props.centerForMerging} view="user" isMergeModalOpen={isMergeModalOpenInList} setIsMergeModalOpen={setIsMergeModalOpenInList} setCenterForMerging={props.setCenterForMerging} preRequest /> : null}
      {isMergeModalOpenInList && props.mergeType === 'pi' ? <MergeViewModal type="investigator" data={props.piForMerging} view="user" isMergeModalOpen={isMergeModalOpenInList} setIsMergeModalOpen={setIsMergeModalOpenInList} setPiForMerging={props.setPiForMerging} preRequest /> : null}

      {isMergeModalOpenInList && props.mergeType === 'parent' ? <MergeViewModal type={props.mergeType} data={props.centerForMerging} view="user" isMergeModalOpen={isMergeModalOpenInList} setIsMergeModalOpen={setIsMergeModalOpenInList} setCenterForMerging={props.setCenterForMerging} preRequest /> : null}
    </>
  );
};

DocumentList.propTypes = {};
export default DocumentList;
