import {
  map, catchError, mergeMap, takeUntil, debounceTime,
} from 'rxjs/operators';
import { stringify } from 'query-string';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { GENERIC_ACTION } from '../SearchResults/logic'
import { apiCall } from '../../../utils';

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
};

const GET_DEEPDIVE_VALUES = 'GET_DEEPDIVE_VALUES';
const GET_DEEPDIVE_VALUES_CANCEL = 'GET_DEEPDIVE_VALUES_CANCEL';
const GET_DEEPDIVE_VALUES_SUCCESS = 'GET_DEEPDIVE_VALUES_SUCCESS';
const GET_DEEPDIVE_VALUES_FAILURE = 'GET_DEEPDIVE_VALUES_FAILURE';
const GET_DEEPDIVE_VALUES_RESET = 'GET_DEEPDIVE_VALUES_RESET';

const GET_TABS_DEEPDIVE_LIST = 'GET_TABS_DEEPDIVE_LIST'
const GET_TABS_DEEPDIVE_LIST_SUCCESS = 'GET_TABS_DEEPDIVE_LIST_SUCCESS'
const GET_TABS_DEEPDIVE_LIST_FAILURE = 'GET_TABS_DEEPDIVE_LIST_FAILURE'
const GET_TABS_DEEPDIVE_VALUES_RESET = 'GET_TABS_DEEPDIVE_VALUES_RESET';

// Auto Complete cancel action
export const getDeepdiveValuesCancelAction = () => ({
  type: GET_DEEPDIVE_VALUES_CANCEL,
});

export const getDeepdiveValuesResetAction = () => ({
  type: GET_DEEPDIVE_VALUES_RESET,
});
// Auto Complete action
export const getDeepdiveValuesAction = (payload) => ({
  type: GET_DEEPDIVE_VALUES,
  payload,
});

// Auto Complete Success action
const getDeepdiveValuesSuccess = (payload) => ({
  type: GET_DEEPDIVE_VALUES_SUCCESS,
  payload,
});

export const getDeepdiveValuesEpic = (actions$) => actions$.pipe(
  ofType(GET_DEEPDIVE_VALUES),
  debounceTime(500),
  mergeMap((action) => ajax(
    apiCall(`${process.env.apiUrl}v0/search/deepdive/details?${stringify(action.payload)}`, 'get'),
  ).pipe(
    map((res) => getDeepdiveValuesSuccess(res)),
    takeUntil(actions$.pipe(ofType(GET_DEEPDIVE_VALUES_CANCEL))),
    catchError((err) => of({
      type: GET_DEEPDIVE_VALUES_FAILURE,
      payload: err,
    })),
  )),
);

export function getDeepdiveValuesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_DEEPDIVE_VALUES: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case GET_DEEPDIVE_VALUES_SUCCESS: {
      return {
        data: action.payload.response.data,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case GET_DEEPDIVE_VALUES_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    case GET_DEEPDIVE_VALUES_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state;
  }
}

export const getTabsDeepdiveListAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_TABS_DEEPDIVE_LIST,
      url: `${process.env.apiUrl}v0/search/tabdata`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI added successfully',
    // hideNotification: false, // hide notification on error
  })
}

export const tabsDeepdiveResetAction = () => ({
  type: GET_TABS_DEEPDIVE_VALUES_RESET,
})

export function tabsDeepdiveListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_TABS_DEEPDIVE_LIST: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_TABS_DEEPDIVE_LIST_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_TABS_DEEPDIVE_LIST_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    case GET_TABS_DEEPDIVE_VALUES_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}

// investigator and center deep-dives details

const GET_SITE_PI_DETAILS_FROM_TRIAL = 'GET_SITE_PI_DETAILS_FROM_TRIAL'
const GET_SITE_PI_DETAILS_FROM_TRIAL_SUCCESS = 'GET_SITE_PI_DETAILS_FROM_TRIAL_SUCCESS'
const GET_SITE_PI_DETAILS_FROM_TRIAL_FAILURE = 'GET_SITE_PI_DETAILS_FROM_TRIAL_FAILURE'
const GET_SITE_PI_DETAILS_FROM_TRIAL_RESET = 'GET_SITE_PI_DETAILS_FROM_TRIAL_RESET';

export const getSitePiDetailsFromTrialAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_SITE_PI_DETAILS_FROM_TRIAL,
      url: `${process.env.apiUrl}v0/search/sitePiFromTrials`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI added successfully',
    // hideNotification: false, // hide notification on error
  })
}

export const getSitePiDetailsFromTrialResetAction = () => ({
  type: GET_SITE_PI_DETAILS_FROM_TRIAL_RESET,
})

export function getSitePiDetailsFromTrialReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SITE_PI_DETAILS_FROM_TRIAL: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_SITE_PI_DETAILS_FROM_TRIAL_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_SITE_PI_DETAILS_FROM_TRIAL_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    case GET_SITE_PI_DETAILS_FROM_TRIAL_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}
