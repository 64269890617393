import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { parse, stringify } from 'query-string';
import {
  Button, Popover, Tooltip, Table,
}
  from 'antd';

import LineChart from '../../../common/components/LineChart';
import BarChart from '../../../common/components/BarChart';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv/index';
import Loader from '../../../common/components/Loader';
import ReadMore from '../../../common/components/ReadMore';
import {
  getStudiesDataAction,
  getStudiesDataMoreAction,
  getStudiesDataResetAction,
  getStudiesGraphAction,
  getStudiesGraphResetAction,
  getStudiesFiltersAction,
  getStudiesFiltersResetAction,
} from '../InvestigatorStudies/logic'
import SortBy from '../RunAnalysis/SortBy';

const graphTypes = ['trend', 'phase', 'status', 'sponsor'];
const filterTypes = ['phase', 'status', 'sponsor', 'indication', 'sources', 'interventions'];

const TrialsAtSite = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [isFiltersPopoverVisible, setIsFiltersPopoverVisible] = useState(false);
  const [activeView, setActiveView] = useState('list');
  const [isSortByPopoverVisible, setIsSortByPopoverVisible] = useState(false);
  const [sortObj, setSortObj] = useState({});
  const [studiesList, setStudiesList] = useState([]);
  const [total, setTotal] = useState(0);
  const [from, setFrom] = useState(1);
  const [filters, setFilters] = useState({});
  const studiesData = useSelector((store) => store.studiesData);
  const studiesGraphData = useSelector((store) => store.studiesGraphData);
  const studiesFiltersData = useSelector((store) => store.studiesFiltersData);

  useEffect(() => {
    return () => {
      dispatch(getStudiesDataResetAction());
      dispatch(getStudiesGraphResetAction());
      dispatch(getStudiesFiltersResetAction());
    }
  }, []);

  useEffect(() => {
    if (parse(location.search).id) {
      setFrom(1);
      setFilters({});
      dispatch(getStudiesDataAction({
        id: parse(location.search).id,
        dataset: 'centers',
        from: 1,
        size: 10,
      }))
    }
    graphTypes.forEach((type) => {
      dispatch(getStudiesGraphAction({
        id: parse(location.search).id,
        dataset: 'centers',
        type,
        from: 1,
        size: 10,
      }, {
        graph_type: type,
        key: type,
      }))
    })
    filterTypes.forEach((type) => {
      dispatch(getStudiesFiltersAction({
        id: parse(location.search).id,
        dataset: 'centers',
        type,
        from: 1,
        size: 10,
      }, {
        graph_type: type,
        key: type,
      }))
    })
  }, [parse(location.search).id])

  useEffect(() => {
    if (studiesData.flag) {
      setStudiesList(studiesData.data.data);
      setTotal(studiesData.data.total);
    }
  }, [JSON.stringify(studiesData)])

  const loadMoreRows = () => {
    dispatch(getStudiesDataMoreAction({
      id: parse(location.search).id,
      dataset: 'centers',
      from: from + 1,
      size: 10,
      filters: JSON.stringify(filters),
      order_by: JSON.stringify(sortObj)
    }))
    setFrom(from + 1);
  }
  const changeActiveView = (view) => {
    if (view !== activeView) {
      setActiveView(view);
    }
  }

  const showGraph = (chartData) => {
    return (
      chartData
      && !chartData?.every((item) => {
        return item.value === 0;
      })
    );
  };

  const updateFilters = (filter, key) => {
    const temp = { ...filters };
    if (temp[key] && temp[key].length) {
      if (temp[key].indexOf(filter.id) > -1) {
        temp[key].splice(temp[key].indexOf(filter.id), 1);
      } else {
        temp[key].push(filter.id);
      }
    } else {
      temp[key] = [filter.id];
    }
    setFilters(temp);
  };
  const onClearFilters = () => {
    setFilters({});
    if (parse(location.search).id) {
      setFrom(1);
      dispatch(getStudiesDataAction({
        id: parse(location.search).id,
        dataset: 'centers',
        from: 1,
        size: 10,
      }))
    }
    graphTypes.forEach((type) => {
      dispatch(getStudiesGraphAction({
        id: parse(location.search).id,
        dataset: 'centers',
        type,
        from: 1,
        size: 10,
      }, {
        graph_type: type,
        key: type,
      }))
    })
    setIsFiltersPopoverVisible(false);
  }

  const onApplyFilters = () => {
    if (filters.indication && filters.indication.length === 0) {
      delete filters['indication'];
    }
    if (filters.sponsor && filters.sponsor.length === 0) {
      delete filters['sponsor'];
    }
    if (filters.phase && filters.phase.length === 0) {
      delete filters['phase'];
    }
    if (filters.status && filters.status.length === 0) {
      delete filters['status'];
    }
    if (parse(location.search).id) {
      setFrom(1);
      dispatch(getStudiesDataAction({
        id: parse(location.search).id,
        dataset: 'centers',
        from: 1,
        size: 10,
        filters: JSON.stringify(filters),
      }))
    }
    graphTypes.forEach((type) => {
      dispatch(getStudiesGraphAction({
        id: parse(location.search).id,
        dataset: 'centers',
        type,
        from: 1,
        size: 10,
        filters: JSON.stringify(filters),
      }, {
        graph_type: type,
        key: type,
      }))
    })
    setIsFiltersPopoverVisible(false);
  }

  const applySort = (srtObj) => {
    setSortObj(srtObj)
    setFrom(1);
    dispatch(getStudiesDataAction({
      id: parse(location.search).id,
      dataset: 'centers',
      from: 1,
      size: 10,
      filters: JSON.stringify(filters),
      order_by: JSON.stringify(srtObj)
    }))
    setIsSortByPopoverVisible(false);
  }

  const renderFilters = () => {
    return (
      <div className="studies-filters-wrap">
        <div className="filter-itm-wrap">
          <div className="filter-title">Indication</div>
          <div className="filter-values-wrap">
            {studiesFiltersData?.data?.indication.map((filter, index) => (
              <div className={`filter-tag-wrap ${filters['indication'] && filters['indication'].length && filters['indication'].indexOf(filter.id) > -1 ? 'active' : ''}`} onClick={() => updateFilters(filter, 'indication')} role="presentation" key={index}>
                <div className="filter-name" title={filter.category}>{filter.category}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="filter-itm-wrap">
          <div className="filter-title">Status</div>
          <div className="filter-values-wrap">
            {studiesFiltersData?.data?.status.map((filter, index) => (
              <div className={`filter-tag-wrap ${filters['status'] && filters['status'].length && filters['status'].indexOf(filter.id) > -1 ? 'active' : ''}`} onClick={() => updateFilters(filter, 'status')} role="presentation" key={index}>
                <div className="filter-name" title={filter.category}>{filter.category}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="filter-itm-wrap">
          <div className="filter-title">Phase</div>
          <div className="filter-values-wrap">
            {studiesFiltersData?.data?.phase.map((filter, index) => (
              <div className={`filter-tag-wrap ${filters['phase'] && filters['phase'].length && filters['phase'].indexOf(filter.id) > -1 ? 'active' : ''}`} onClick={() => updateFilters(filter, 'phase')} role="presentation" key={index}>
                <div className="filter-name" title={filter.category}>{filter.category}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="filter-itm-wrap">
          <div className="filter-title">Sponsor</div>
          <div className="filter-values-wrap">
            {studiesFiltersData?.data?.sponsor.slice(0, 20).map((filter, index) => (
              <div className={`filter-tag-wrap ${filters['sponsor'] && filters['sponsor'].length && filters['sponsor'].indexOf(filter.id) > -1 ? 'active' : ''}`} onClick={() => updateFilters(filter, 'sponsor')} role="presentation" key={index}>
                <div className="filter-name" title={filter.category}>{filter.category}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="filter-itm-wrap">
          <div className="filter-title">Interventions</div>
          <div className="filter-values-wrap">
            {studiesFiltersData?.data?.interventions.slice(0, 20).map((filter, index) => (
              <div className={`filter-tag-wrap ${filters['interventions'] && filters['interventions'].length && filters['interventions'].indexOf(filter.id) > -1 ? 'active' : ''}`} onClick={() => updateFilters(filter, 'interventions')} role="presentation" key={index}>
                <div className="filter-name" title={filter.category}>{filter.category}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="filter-itm-wrap">
          <div className="filter-title">Sources</div>
          <div className="filter-values-wrap">
            {studiesFiltersData?.data?.sources.slice(0, 20).map((filter, index) => (
              <div className={`filter-tag-wrap ${filters['sources'] && filters['sources'].length && filters['sources'].indexOf(filter.id) > -1 ? 'active' : ''}`} onClick={() => updateFilters(filter, 'sources')} role="presentation" key={index}>
                <div className="filter-name" title={filter.category}>{filter.category}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="filter-submit-btns">
          <Button className="clear-btn" onClick={onClearFilters}>Clear</Button>
          <Button className="apply-btn" type="primary" onClick={onApplyFilters}>Apply</Button>
        </div>
      </div>
    )
  }

  const renderStudies = () => {
    if (studiesData.flag && studiesList.length) {
      return studiesList.map((studyItm, index) => (
        <div className="study-card-wrap" key={index}>
          <div className="card">
            <div className="card-header card-header-notitle card-header-investigator">
              <div className="card-meta">
                <div className="card-meta-footer">
                  <div className="source">
                    <a
                      target="_blank"
                      href={`/search/ctdetails?${stringify({
                        ...parse(location.search),
                        id: studyItm.iid,
                        cid: studyItm.clinical_ids,
                        type: studyItm.type,
                      })}`}
                      rel="noopener noreferrer"
                      className="card-ct-link"
                    >
                      <Tooltip title="View Study">
                        <div className="source-img" />
                      </Tooltip>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-title">{studyItm.title}</div>
            <div className="card-content">
              <div className="card-content-data">
                <div className="title">Status:</div>
                <div className="desc">{studyItm.trial_status}</div>
              </div>
              <div className="card-content-data">
                <div className="title">Study start date:</div>
                <div className="desc">{studyItm.date}</div>
              </div>
              <div className="card-content-data">
                <div className="title">Phase:</div>
                <div className="desc">{studyItm.phase}</div>
              </div>
              <div className="card-content-data">
                <div className="title">Trial ID:</div>
                <div className="desc">{studyItm.trial_id}</div>
              </div>
              <div className="card-content-data">
                <div className="title">Clinical ID:</div>
                <div className="desc">{studyItm.clinical_ids.join(', ')}</div>
              </div>
              <div className="card-content-data">
                <div className="title">LandSCAPE ID:</div>
                <div className="desc">{studyItm.landscape_id}</div>
              </div>

              <div className="card-content-data">
                <div className="title">Parexel study ID:</div>
                <div className="desc">{studyItm.pxl_study_no ? studyItm.pxl_study_no : '-'}</div>
              </div>

              <div className="card-content-data">
                <div className="title">Recruitment Factor:</div>
                <div className="desc">{studyItm.trial_srfsa ? studyItm.trial_srfsa : '-'}</div>
              </div>

              <div className="card-content-data">
                <div className="title">Startup Factor:</div>
                <div className="desc">{studyItm.trial_ssfsa ? studyItm.trial_ssfsa : '-'}</div>
              </div>
              <div className="card-content-data">
                <div className="title">Enrolled subjects:</div>
                <div className="desc">{studyItm.site_enrollment_subjects ? studyItm.site_enrollment_subjects : '-'}</div>
              </div>

            </div>
            <div className="card-content">
              <div className="card-content-data">
                <div className="title">Intervention:</div>
                <div className="desc">{studyItm.interventions && studyItm.interventions.length ? studyItm.interventions.join('; ') : '-'}</div>
              </div>
              <div className="card-content-data">
                <div className="title">Indication:</div>
                <div className="desc">{studyItm.indications && studyItm.indications.length ? studyItm.indications.join('; ') : '-'}</div>
              </div>
            </div>
            <div className="card-content-data">
              <div className="title">Sources:</div>
              <div className="desc">{studyItm.sources ? studyItm.sources.join('; ') : '-'}</div>
              {studyItm.total_payments && <div role="presentation" className="view-more">view more</div>}
            </div>
            <div className="card-content">
              <div className="card-content-data">
                <div className="title">Investigators:</div>
                <div className="desc">
                  <ReadMore type="text" limit={100}>
                    {studyItm.related_investigators && studyItm.related_investigators.length ? studyItm.related_investigators.join('; ') : '-'}
                  </ReadMore>
                </div>
              </div>
              <div className="card-content-data">
                <div className="title">Sponsor:</div>
                <div className="desc">{studyItm.sponsors && studyItm.sponsors.length ? studyItm.sponsors.join('; ') : '-'}</div>
              </div>
            </div>
          </div>
        </div>
      ))
    }
    if (studiesData.flag && !studiesList.length) {
      return (
        <div className="no-data-found-msg">
          No relevant data found
        </div>
      );
    }
    return null;
  }

  const renderGraphs = () => {
    return (
      <div className="graphs-wrap graph-row">
        <div className="graph-sec">
          <div className="graph-header">
            <div className="graph-title">Trend</div>
          </div>
          <div className="graph-view">
            {showGraph(studiesGraphData?.data?.trend) ? (
              <LineChart data={studiesGraphData?.data?.trend} />
            ) : (
              <span className="no-data-inside">No data available</span>
            )}
          </div>
        </div>
        <div className="graph-sec">
          <div className="graph-header">
            <div className="graph-title">Phases vs Studies</div>
          </div>
          <div className="graph-view barchart">
            {showGraph(studiesGraphData?.data?.phase) ? (
              <BarChart
                data={studiesGraphData?.data?.phase}
                width={350}
                height={260}
                top={15}
                bottom={90}
                left={30}
                right={0}
                studies="phase"
                checkDataset="centers"
                titleName="Phase"
                showModal
              />
            ) : (
              <span className="no-data-inside">No data available</span>
            )}
          </div>
        </div>
        <div className="graph-sec">
          <div className="graph-header">
            <div className="graph-title">Status vs Studies</div>
          </div>
          <div className="graph-view barchart">
            {showGraph(studiesGraphData?.data?.status) ? (
              <BarChart
                data={studiesGraphData?.data?.status}
                width={350}
                height={260}
                top={15}
                bottom={90}
                left={30}
                right={0}
                studies="status"
                checkDataset="centers"
                titleName="Status"
                showModal
              />
            ) : (
              <span className="no-data-inside">No data available</span>
            )}
          </div>
        </div>
        <div className="graph-sec">
          <div className="graph-header">
            <div className="graph-title">Sponsor vs Studies</div>
          </div>
          <div className="graph-view barchart">
            {showGraph(studiesGraphData?.data?.sponsor) ? (
              <BarChart
                data={studiesGraphData?.data?.sponsor.slice(0, 20)}
                width={350}
                height={260}
                top={15}
                bottom={90}
                left={30}
                right={0}
                studies="sponsor"
                checkDataset="centers"
                titleName="Sponsor"
                showModal
              />
            ) : (
              <span className="no-data-inside">No data available</span>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="investigator-studies-wrap">
      <div className="studies-page-icons">
        <div className="meta-btn-row">
          <div className="sortby-icon">
            <Popover
              overlayClassName="sortby-runanalysis-popover"
              placement="left"
              title="Sort by:"
              content={<SortBy pageName="studies" onApplySort={applySort} />}
              trigger="click"
              open={isSortByPopoverVisible}
              onOpenChange={(visibled) => {
                setIsSortByPopoverVisible(visibled);
              }}
            >
              <Tooltip title="Sort">
                <div className="sort-icon-img" />
              </Tooltip>
            </Popover>
          </div>
          <div className="filter-icon">
            <Popover
              overlayClassName="filter-studies-popover"
              placement="left"
              title=""
              content={studiesFiltersData.flag && Object.keys(studiesFiltersData.data).length === 6 ? renderFilters() : null}
              trigger="click"
              open={isFiltersPopoverVisible}
              onOpenChange={(visibled) => {
                setIsFiltersPopoverVisible(visibled);
              }}
              destroyTooltipOnHide
            >
              <Tooltip title="Filters">
                <div className="filter-icon-img" />
              </Tooltip>
            </Popover>
          </div>

          <Tooltip title="List View">
            <div className={`list-icon ${activeView === 'list' ? 'active' : ''}`}>
              <div className="list-icon-img" role="presentation" onClick={() => changeActiveView('list')} />
            </div>
          </Tooltip>

          <Tooltip title="Analytics View">
            <div className={`stat-icon ${activeView === 'graph' ? 'active' : ''}`}>
              <div className="stat-icon-img" role="presentation" onClick={() => changeActiveView('graph')} />
            </div>
          </Tooltip>
        </div>
      </div>
      {activeView === 'list'
        ? (
          <>
            {studiesData.flag && studiesList.length
              ? (
                <div className="show-doc-outof show-doc-outof-w-relative" style={{ marginBottom: '10px' }}>
                  <div className="show-doc-data">
                    Showing<span className="show-count">{studiesList.length}</span> out of
                    <span className="show-count-number">{studiesData.data.total}</span>studies
                  </div>
                </div>
              )
              : null}
            <div className="investigator-studies-list-wrap">
              <Loader loading={!studiesList.length && studiesData.loading} error={studiesData.error}>
                <LazyLoadDiv
                  className="card-list scrollbar"
                  id="trialsatsite-list"
                  total={total}
                  currentTotal={(studiesList || []).length}
                  loadMoreRows={() => loadMoreRows()}
                  height="calc(100vh - 380px)"
                >
                  {renderStudies()}
                </LazyLoadDiv>
              </Loader>
            </div>
          </>
        )
          : (
            <div className="investigator-studies-graph-wrap">
              <Loader loading={studiesGraphData.loading} error={studiesGraphData.error}>
                {renderGraphs()}
              </Loader>
            </div>
          )}
    </div>
  )
}

export default TrialsAtSite;
